export const COMETCHAT_CONSTANTS = {
  STAGING: {
    APP_ID: "241639cc23984652",
    REGION: "us",
    AUTH_KEY: "8f04f618b3e3a304e97fc9a3bbef22af6427ac77",
    apiUrl: 'https://api.staging.truleague.com/api/',

  },
  PRODUCTION: {
    APP_ID: "243298040a731324",
    REGION: "us",
    AUTH_KEY: "10f07caa30d9a1e60b246c09cb9b208daef9e35f",
    apiUrl: 'https://portal.api.truleague.com/api/',

  },
};
