import {COMETCHAT_CONSTANTS} from './environment'
import { getDataFromParams } from './getUrlParams';
export const Authlogin = async (token) => {
    try {
      if (!token) {
        throw new Error("Token is required for authentication.");
      }
  
      const baseUrl = window.location.href; 
      const apiUrl = `${baseUrl}/verify_token/${token}`; 
  
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        return { success: true }; 
      } else {
        throw new Error(`API call failed with status: ${response.status}`); 
      }
    } catch (error) {
      console.error("Error in Authlogin:", error.message);
      return { success: false, error: error.message }; 
    }
  };

export const getGroupDetails = async (groupId) => {
  const Mode = getDataFromParams("Mode");
  let baseUrl;
  if (Mode === 'STAGING') {
    baseUrl = COMETCHAT_CONSTANTS.STAGING.apiUrl;
  } else {
    baseUrl = COMETCHAT_CONSTANTS.PRODUCTION.apiUrl;
  }

  try {
    if (!groupId) throw new Error("Group ID is required.");

    const response = await fetch(`${baseUrl}common/get_group_url/${groupId}`);

    if (!response.ok) {
      throw new Error(`Failed to fetch group details: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching group details:", error);
    throw error;
  }
};

export const postGroupDetails = async (formData) => {
  const Mode = getDataFromParams("Mode");
  let baseUrl;
  if (Mode === 'STAGING') {
    baseUrl = COMETCHAT_CONSTANTS.STAGING.apiUrl;
  } else {
    baseUrl = COMETCHAT_CONSTANTS.PRODUCTION.apiUrl;
  }

  try {
    const response = await fetch(`${baseUrl}common/update_group_type`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error(`Failed to update group type: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating group type:", error);
    throw error;
  }
};

