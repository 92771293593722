import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { CometChatBackdrop } from "../";
import Translator from "../../../resources/localization/translator";

import {
	alertWrapperStyle,
	alertMessageStyle,
	alertButtonStyle,
} from "./style";

class CometChatConfirmDialog extends React.Component {

	handleButtonClick = (e) => {	
		const isConfirmed = e.target.value === 'yes';
		this.props.onClick(isConfirmed); 
	};

	render() {
		const confirmButtonText = this.props?.confirmButtonText
			? this.props.confirmButtonText
			: Translator.translate("YES", this.context.language);
			const cancelButtonText = this.props?.cancelButtonText
			? this.props.ShouldLeaveDialog && this.props.RemoveDialog
			  ? "Don’t remove" 
			  : this.props.ShouldLeaveDialog
				? "Cancel" 
				: this.props.cancelButtonText 
			: Translator.translate("NO", this.context.language); 
		  

		return (
			<React.Fragment>
				<CometChatBackdrop
					show={true}
					style={this.props?.ShouldLeaveDialog ? {} : { position: "absolute" }} 
					clicked={this.props.close}
					checkLeave={true} 
				/>			

				{/* Handle "Leave" Dialog */}
				{this.props?.ShouldLeaveDialog && !this.props.RemoveDialog && (
					<div className='confirm__dialog' css={alertWrapperStyle(this.props)}>
						<div className='confirm__message' css={alertMessageStyle(this.props)}>
							{/* {`Leave "${this.props?.GroupName}" group?`} */}
							{`Are you sure you want to exit the group?`}
						</div>
						<div className='confirm__buttons' css={alertButtonStyle(this.props)}>
							<button 
								type='button' 
								value='yes' 
								onClick={this.props.onClick}
							>
								{confirmButtonText}
							</button>
							<button 
								type='button' 
								value='no' 
								onClick={this.props.onClick}
							>
								{cancelButtonText}
							</button>
						</div>
					</div>
				)}

				{/* Handle "Remove Member" Dialog */}
				{this.props.ShouldLeaveDialog && this.props.RemoveDialog && (
					<div className='confirm__dialog' css={alertWrapperStyle(this.props)}>
						<div className='confirm__message' css={alertMessageStyle(this.props)}>
							{`Remove "${this.props.membername}" from "${this.props?.GroupName}" group?`}
						</div>
						<div className='confirm__buttons' css={alertButtonStyle(this.props)}>
							<button 
								type='button' 
								value='yes' 
								onClick={this.handleButtonClick}
							>
								{confirmButtonText}
							</button>
							<button 
								type='button' 
								value='no' 
								onClick={() => this.props.onClick(false)} 
								style={{ marginLeft: "2px" }}>
								{cancelButtonText}
							</button>
						</div>
					</div>
				)}

				{/* Default Dialog */}
				{!this.props.ShouldLeaveDialog && !this.props.RemoveDialog && (
					<div className='confirm__dialog' css={alertWrapperStyle(this.props)}>
						<div className='confirm__message' css={alertMessageStyle(this.props)}>
							{this.props?.message}
						</div>
						<div className='confirm__buttons' css={alertButtonStyle(this.props)}>
							<button 
								type='button' 
								value='no' 
								onClick={this.props.onClick}
							>
								{cancelButtonText}
							</button>
							<button 
								type='button' 
								value='yes' 
								onClick={this.props.onClick}
							>
								{confirmButtonText}
							</button>
						</div>
					</div>
				)}

			</React.Fragment>
		);
	}
}

export { CometChatConfirmDialog };
